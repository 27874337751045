import _ from '@/utils/lodash'
import { VIGILANTE } from '@/utils/consts'
import { APIFilter } from '@/utils/api'

export default {
  async selectVigilantesCuadrante (Vue, idpuestoServicioServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
      .addGT('estado', 0)
    let resp = await Vue.$api.call('cuadrante.selectVigilanteCuadrante', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async selectVigilantesHabituales (Vue, idpuestoServicioServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
      .addGT('estado', 0)
      .addExact('idtrelacion', VIGILANTE.idtrelacion.habitual)
    let resp = await Vue.$api.call('vigilantePuestoServicio.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async selectVigilantes (Vue, idsvigilantes) {
    const apiFilter = new APIFilter()
    apiFilter
      .addIn('idvigilante', idsvigilantes)
      .addGT('estado', 0)
    const resp = await Vue.$api.call(
      'vigilante.select',
      {
        filter: apiFilter,
        sorter: [
          { 'field': 'codigo', 'asc': true }
        ]
      }
    )
    return resp.data.result.dataset
  },
  async selectCuadrante (Vue, idsvigilantes, firstAndLastDay) {
    const apiFilter = new APIFilter()
    apiFilter
      .addIn('idvigilante', idsvigilantes)
      .addGT('estado', 0)
      .addGTE('fecha', firstAndLastDay.firstDay)
      .addLTE('fecha', firstAndLastDay.lastDay)
    const resp = await Vue.$api.call('cuadrante.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async selectCuadranteNoLaboral (Vue, vigilantes, firstAndLastDay) {
    const idsvigilantes = _.map(vigilantes, (item) => {
      return item.idvigilante
    })
    const apiFilter = new APIFilter()
    apiFilter
      .addIn('idvigilante', idsvigilantes)
      .addGT('estado', 0)
      .addGTE('fecha', firstAndLastDay.firstDay)
      .addLTE('fecha', firstAndLastDay.lastDay)
    let resp = await Vue.$api.call('cuadrantenoLaboral.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async selectServicio (Vue, idpuestoServicioServicio) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
    const resp = await Vue.$api.call('puestoServicioServicio.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectTiposTurnoDisponibles (Vue, idpuestoServicioServicio) {
    const apiFilterServicio = new APIFilter()
    apiFilterServicio
      .addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
      .addGT('estado', 0)
    const respTurnosServicio = await Vue.$api.call(
      'tturnoPuestoServicio.select', { filter: apiFilterServicio }
    )
    const turnosServicio = respTurnosServicio.data.result.dataset
    const apiFilterTurnos = new APIFilter()
    apiFilterTurnos.addGT('estado', 0)
    const respTurnos = await Vue.$api.call('tturno.select', { filter: apiFilterTurnos })
    // quitar tipos de turnos laborales no disponibles
    let tiposTurnoDisponibles = []
    for (let tipoTurno of respTurnos.data.result.dataset) {
      if ((!tipoTurno.laboral) || (_.find(turnosServicio, (item) => { return item.idtturno === tipoTurno.idtturno }))) {
        tiposTurnoDisponibles.push(tipoTurno)
      }
    }
    return tiposTurnoDisponibles
  },
  async asignarCuadrante (Vue, idcuadrante, idvigilante) {
    await Vue.$api.call('cuadrante.asignar', {
      idcuadrante,
      idvigilante
    })
  },
  async desasignarCuadrante (Vue, idcuadrante) {
    await Vue.$api.call('cuadrante.desasignar', { idcuadrante: idcuadrante })
  },
  async deleteCuadranteNoLaboral (Vue, idcuadranteNoLaboral) {
    await Vue.$api.call('cuadrantenoLaboral.delete', { idcuadrante_nolaboral: idcuadranteNoLaboral })
  },
  async insertCuadranteNoLaboral (Vue, idvigilante, fecha, idtcuadranteNoLaboral, horaDesde, horaHasta) {
    const resp = await Vue.$api.call(
      'cuadrantenoLaboral.insert',
      {
        values: {
          idvigilante,
          fecha,
          idtcuadrante_nolaboral: idtcuadranteNoLaboral,
          hora_desde: horaDesde,
          hora_hasta: horaHasta,
          // este valor no es nulable, pongo 0 porque es lo que hace Delphi GLP-23243
          horas_computo: 0,
        },
      }
    )
    return resp.data.result.dataset[0]
  },
  async selectCuadranteLibre (Vue, idpuestoServicioServicio, fecha, idtturno) {
    const filterCuadrante = new APIFilter()
    filterCuadrante
      .addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
      .addGT('estado', 0)
      .addExact('fecha', fecha)
      .addExact('idtturno', idtturno)
      .addIsNull('idvigilante')
    const resp = await Vue.$api.call('cuadrante.select', { filter: filterCuadrante })
    return resp.data.result.dataset
  },
  async selectFaltaCompletar (Vue, idpuestoServicioServicio, fecha) {
    const filterCuadrante = new APIFilter()
    filterCuadrante
      .addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
      .addGT('estado', 0)
      .addExact('fecha', fecha)
      .addIsNull('idvigilante')
    const resp = await Vue.$api.call('cuadrante.select', { filter: filterCuadrante })
    let faltaCompletar = []
    for (let turno of resp.data.result.dataset) {
      const existe = _.find(faltaCompletar, (item) => {
        return item.alias === turno.alias
      })
      if (existe) {
        existe.cantidad += 1
      } else {
        faltaCompletar.push({
          alias: turno.alias,
          descripcion: turno.tturno_descripcion,
          cantidad: 1
        })
      }
    }
    return faltaCompletar
  },
  async selectFestivos (Vue, idpuestoServicio, firstAndLastDay) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpuesto_servicio', idpuestoServicio)
      .addGTE('fecha', firstAndLastDay.firstDay)
      .addLTE('fecha', firstAndLastDay.lastDay)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('puestoServicioFestivo.select', { filter: apiFilter })
    return resp.data.result.dataset
  },
  async addVigilantePuestoServicio (Vue, idvigilante, idpuestoServicioServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpuesto_servicio_servicio', idpuestoServicioServicio)
      .addGT('estado', 0)
    let resp = await Vue.$api.call('vigilantePuestoServicio.select', { filter: apiFilter })
    const vigilantePuestoServicio = resp.data.result.dataset
    const existe = _.find(vigilantePuestoServicio, (item) => {
      return item.idvigilante === idvigilante
    })
    if (!existe) {
      await Vue.$api.call(
        'vigilantePuestoServicio.insert',
        {
          values: {
            idvigilante,
            idtrelacion: VIGILANTE.idtrelacion.habitual,
            idpuesto_servicio_servicio: idpuestoServicioServicio,
          },
        }
      )
    }
  }
}
