<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content
      :padding="3"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :hide-default-footer="true"
        :hide-default-header="true"
        no-data-text=""
        :mobile-breakpoint="0"
      >
        <template #header="{ props: { headers } }">
          <tr>
            <th
              v-for="header in headers"
              :key="`header_${header.text}`"
              @click="clickHeader(header)"
            >
              <div
                v-if="header.value === 'name'"
                class="vigilante"
              >
                <!-- nada -->
              </div>
              <div
                v-else
                class="text-center dia"
              >
                {{ header.dayText }}
                <br>
                <div
                  :class="['text-center', colorHeader(header)]"
                >
                  {{ header.text }}
                </div>
              </div>
            </th>
          </tr>
        </template>
        <template #item="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td
              v-for="day in columnsTable"
              :key="`day_${day.day}`"
              :class="['text-center', 'ma-0', 'pa-0', { 'cell-selected': isCellSelected(day.columnName, props.item, props.index), otroTurno: isOtroTurno(day.columnName, props.index) }]"
              @click.stop="clickTableCell(day.columnName, props.item, props.index)"
            >
              {{ isCellSelected(day.columnName, props.item, props.index) && !props.item[day.columnName] ? turnoKeyCodes : props.item[day.columnName] }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <vigilante-add
        v-if="servicio && servicio.iddelegacion"
        :showing.sync="showingDialogs.addVigilante"
        :iddelegacion="servicio.iddelegacion"
        @submit="submitAddVigilante"
      />
      <b10-fab-button
        left
        :icon="$vuetify.icons.values.prev"
        @click="clickSiguienteAnteriorMes(-1)"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.next"
        @click="clickSiguienteAnteriorMes(1)"
      />
    </b10-page-content>
    <cuadrante-edit-legend
      :showing.sync="showingDialogs.legends"
    />
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import _ from '@/utils/lodash'
import { pad } from '@/utils/format'
import Data from './CuadranteEditData'
import { TCUADRANTE_NOLABORAL, ORA } from '@/utils/consts'
import CuadranteEditLegend from './components/CuadranteEditLegend'
import VigilanteAdd from './components/VigilanteAdd'
import {
  encodeDate, format as formatDate, getDaysInMonth, lastDayOfMonth, getDay, firstDayOfMonth, isWeekend, getWeekDay,  dayNames, addMonths
} from '@/utils/date'

export default {
  components: {
    CuadranteEditLegend, VigilanteAdd,
  },
  mixins: [formPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      servicio: {},
      vigilantes: [],
      cuadrante: [],
      cuadranteNoLaboral: [],
      daysInMonth: null,
      firstAndLastDay: {},
      turnoKeyCodes: '',
      tableCurrentCell: null,
      tableHeaders: [],
      tableItems: [],
      toolbarOptions: {
        addVigilante: {
          title: 'Añadir vigilante',
          visible: true,
          icon: 'add'
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        }
      },
      tiposTurnoDisponibles: [],
      festivos: [],
      vigilantesCuadrante: [],
      vigilantesHabituales: [],
      keys: {
        intro: 13,
        delete: 46,
        up: 38,
        right: 39,
        down: 40,
        left: 37
      },
      showingDialogs: {
        addVigilante: false,
        legends: false,
      },
    }
  },
  computed: {
    columnsTable () {
      let columns = []
      for (let day = 1; day < this.daysInMonth + 1; day++) {
        columns.push({
          day: day,
          columnName: 'd' + pad(day, 2, '0')
        })
      }
      return columns
    }
  },
  async created () {
    window.addEventListener('keyup', this.keyupTable)
    await this.initStore()
    await this.loadPage()
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyupTable)
  },
  methods: {
    async loadPage () {
      const fecha = encodeDate(this.routeParams.year, this.routeParams.month - 1)
      this.servicio = await Data.selectServicio(this, this.routeParams.idpuesto_servicio_servicio)
      this.title = `Cuadrante ${formatDate(fecha, 'MMMM yyyy')} - ${this.servicio.descripcion} - ${this.servicio.puesto_servicio_descripcion}`
      this.daysInMonth = getDaysInMonth(fecha)
      this.firstAndLastDay = {
        firstDay: firstDayOfMonth(fecha),
        lastDay: lastDayOfMonth(fecha),
      }
      this.tiposTurnoDisponibles = await Data.selectTiposTurnoDisponibles(
        this, this.routeParams.idpuesto_servicio_servicio
      )
      this.festivos = await Data.selectFestivos(
        this, this.routeParams.idpuesto_servicio, this.firstAndLastDay
      )
      this.vigilantesCuadrante = await Data.selectVigilantesCuadrante(
        this, this.routeParams.idpuesto_servicio_servicio
      )
      this.vigilantesHabituales = await Data.selectVigilantesHabituales(
        this, this.routeParams.idpuesto_servicio_servicio
      )
      await this.cargarTabla()
    },
    clickTableCell (columnName, item, index) {
      this.tableCurrentCell = {
        columnName,
        index
      }
      this.turnoKeyCodes = ''
    },
    currentColumn () {
      if (this.tableCurrentCell) {
        return parseInt(this.tableCurrentCell.columnName.substr(1, 2))
      }
    },
    currentRow () {
      if (this.tableCurrentCell) {
        return this.tableCurrentCell.index
      }
    },
    isCellSelected (columnName, item, index) {
      return (
        (this.tableCurrentCell) &&
        (this.tableCurrentCell.columnName === columnName) &&
        (this.tableCurrentCell.index === index)
      )
    },
    async cargarTabla () {
      let idsvigilantes = _.uniqBy(
        _.map(this.vigilantesCuadrante, 'idvigilante').concat(_.map(this.vigilantesHabituales, 'idvigilante'))
      )
      this.vigilantes = await Data.selectVigilantes(this, idsvigilantes)
      this.cuadrante = await Data.selectCuadrante(this, idsvigilantes, this.firstAndLastDay)
      this.cuadranteNoLaboral = await Data.selectCuadranteNoLaboral(this, this.vigilantes, this.firstAndLastDay)
      // table headers
      this.tableHeaders.push(
        {
          text: '',
          value: 'name',
          align: 'left',
          sortable: false
        }
      )
      if (this.daysInMonth) {
        const diasSemana = dayNames()
        for (let day = 1; day < this.daysInMonth + 1; day++) {
          let weekday = getWeekDay(encodeDate(this.routeParams.year, this.routeParams.month - 1, day))
          this.tableHeaders.push({
            dayText: diasSemana[weekday],
            text: day,
            value: 'd' + pad(day, 2, '0'),
            sortable: false,
            align: 'center'
          })
        }
      }
      // table items
      for (let vigilante of this.vigilantes) {
        let obj = {}
        obj.value = false
        obj.name = `${vigilante.codigo} - ${vigilante.nombre_vigilante}`
        for (let day = 1; day < this.daysInMonth + 1; day++) {
          obj['d' + pad(day, 2, '0')] = ''
        }
        this.tableItems.push(obj)
      }
      for (let item of this.cuadrante) {
        let idvigilante = item.idvigilante
        let indexVigilante = null
        for (let vigilante in this.vigilantes) {
          if (this.vigilantes[vigilante].idvigilante === idvigilante) {
            indexVigilante = vigilante
            break
          }
        }
        if ((indexVigilante !== null) && (indexVigilante >= 0)) {
          let dia = 'd' + pad(getDay(item.fdesde), 2, '0')
          this.tableItems[indexVigilante][dia] = item.alias
        }
      }
      for (let item of this.cuadranteNoLaboral) {
        let idvigilante = item.idvigilante
        let indexVigilante = null
        for (let vigilante in this.vigilantes) {
          if (this.vigilantes[vigilante].idvigilante === idvigilante) {
            indexVigilante = vigilante
            break
          }
        }
        if ((indexVigilante !== null) && (indexVigilante >= 0)) {
          let dia = 'd' + pad(getDay(item.fecha), 2, '0')
          this.tableItems[indexVigilante][dia] = item.alias
        }
      }
    },
    selectNextCell () {
      if (this.daysInMonth > this.currentColumn()) {
        this.tableCurrentCell.columnName = 'd' + pad(this.currentColumn() + 1, 2, '0')
      } else if (this.currentRow() < this.vigilantes.length - 1) {
        this.tableCurrentCell.columnName = 'd01'
        this.tableCurrentCell.index = this.currentRow() + 1
      }
    },
    selectPrevCell () {
      let firstDay = getDay(this.firstAndLastDay.firstDay)
      let lastDay = getDay(this.firstAndLastDay.lastDay)
      if (!this.isCellSelected('d01', null, 0)) {
        if (this.currentColumn() - 1 < firstDay) {
          this.tableCurrentCell.columnName = 'd' + pad(lastDay, 2, '0')
          this.tableCurrentCell.index = this.currentRow() - 1
        } else if (this.tableCurrentCell.index >= 0) {
          this.tableCurrentCell.columnName = 'd' + pad(this.currentColumn() - 1, 2, '0')
        }
      }
    },
    selectHigherCell () {
      this.tableCurrentCell.columnName = 'd' + pad(this.currentColumn(), 2, '0')
      this.tableCurrentCell.index = this.currentRow() - 1
      if (this.currentRow() < 0) {
        this.tableCurrentCell.index = this.vigilantes.length - 1
      }
    },
    selectLowerCell () {
      this.tableCurrentCell.columnName = 'd' + pad(this.currentColumn(), 2, '0')
      this.tableCurrentCell.index = this.currentRow() + 1
      if (this.currentRow() > this.vigilantes.length - 1) {
        this.tableCurrentCell.index = 0
      }
    },
    setCurrentTipoTurno (tipoTurno) {
      this.tableItems[this.tableCurrentCell.index][this.tableCurrentCell.columnName] = tipoTurno
    },
    async keyupTable (event) {
      if (this.tableCurrentCell) {
        if (event.keyCode === this.keys.intro) {
          if (this.tableItems[this.tableCurrentCell.index][this.tableCurrentCell.columnName]) {
            this.selectNextCell()
            this.turnoKeyCodes = ''
          } else {
            const turno = _.find(this.tiposTurnoDisponibles, (item) => {
              return item.alias === this.turnoKeyCodes
            })
            if ((this.turnoKeyCodes.length === 1 || this.turnoKeyCodes.length === 2) && turno) {
              const diaSelected = this.currentColumn()
              // es un turno definido en el puesto de servicio o
              // un tipo de turno no laboral?
              if (turno.laboral) {
                this.asignarCuadrante(
                  this.routeParams.year, this.routeParams.month - 1, diaSelected,
                  this.routeParams.idpuesto_servicio_servicio, turno.idtturno,
                  this.vigilantes[this.tableCurrentCell.index].idvigilante
                ).catch(error => {
                  if (error.code === ORA.errors.ORA_APPLICATION_ERROR) {
                    this.turnoKeyCodes = ''
                  }
                })
              } else if (!turno.laboral) {
                this.insertCuadranteNoLaboral(
                  this.routeParams.year, this.routeParams.month - 1, diaSelected,
                  this.vigilantes[this.tableCurrentCell.index].idvigilante
                )
              }
            } else if (this.turnoKeyCodes.length === 0) {
              this.selectNextCell()
            } else {
              this.$alert.showSnackbarError(`No existe un tipo de turno ${this.turnoKeyCodes}`)
              this.turnoKeyCodes = ''
            }
          }
        } else if (event.keyCode === this.keys.delete && !this.isOtroTurno(this.tableCurrentCell.columnName, this.tableCurrentCell.index)) {
          this.turnoKeyCodes = ''
          if (this.tableItems[this.tableCurrentCell.index][this.tableCurrentCell.columnName]) {
            // borrar un cuadrante
            let cuadranteSelected = _.find(this.cuadrante, (item) => {
              return (
                this.vigilantes[this.tableCurrentCell.index].idvigilante === item.idvigilante &&
                this.currentColumn() === getDay(item.fdesde)
              )
            })
            if (cuadranteSelected) {
              this.desasignarCuadrante(cuadranteSelected.idcuadrante)
            } else {
              // borrar un cuadrante no laboral
              let cuadranteNoLaboralSelected = _.find(this.cuadranteNoLaboral, (item) => {
                return (
                  this.vigilantes[this.tableCurrentCell.index].idvigilante === item.idvigilante &&
                  this.currentColumn() === getDay(item.fecha)
                )
              })
              if (cuadranteNoLaboralSelected) {
                this.deleteCuadranteNoLaboral(cuadranteNoLaboralSelected.idcuadrante_nolaboral)
              }
            }
          }
        } else if (event.keyCode === this.keys.up) {
          this.turnoKeyCodes = ''
          this.selectHigherCell()
        } else if (event.keyCode === this.keys.right) {
          this.turnoKeyCodes = ''
          this.selectNextCell()
        } else if (event.keyCode === this.keys.down) {
          this.turnoKeyCodes = ''
          this.selectLowerCell()
        } else if (event.keyCode === this.keys.left) {
          this.turnoKeyCodes = ''
          this.selectPrevCell()
        } else {
          const regex = /^[a-z0-9]+$/i
          const charKeyup = String.fromCharCode(event.keyCode)
          if (regex.test(charKeyup) && this.turnoKeyCodes.length < 2) {
            this.turnoKeyCodes += charKeyup
          }
        }
      }
    },
    async asignarCuadrante (year, month, day, idpuestoServicioServicio, idtturno, idvigilante) {
      let fecha = encodeDate(year, month, day)
      let cuadranteLibre = await Data.selectCuadranteLibre(
        this, idpuestoServicioServicio, fecha, idtturno
      )
      if (cuadranteLibre.length > 0) {
        // actualizar remoto
        await Data.asignarCuadrante(
          this, cuadranteLibre[0].idcuadrante, idvigilante
        )
        // actualizar local
        let cuadranteAsignado = _.find(this.cuadrante, (item) => {
          return cuadranteLibre[0].idcuadrante === item.idcuadrante
        })
        if (cuadranteAsignado) {
          cuadranteAsignado.idvigilante = idvigilante
        }
        this.setCurrentTipoTurno(this.turnoKeyCodes)
        this.turnoKeyCodes = ''
        this.selectNextCell()
      } else {
        this.$alert.showSnackbarError(
          `No existe ningún turno ${this.turnoKeyCodes} sin cubrir`
        )
        this.turnoKeyCodes = ''
      }
    },
    async desasignarCuadrante (idcuadrante) {
      // actualizar remoto
      await Data.desasignarCuadrante(this, idcuadrante)
      // actualizar local
      let cuadranteAsignado = _.find(this.cuadrante, (item) => {
        return idcuadrante === item.idcuadrante
      })
      if (cuadranteAsignado) {
        cuadranteAsignado.idvigilante = null
      }
      this.setCurrentTipoTurno(null)
    },
    async insertCuadranteNoLaboral (year, month, day, idvigilante) {
      let fecha = encodeDate(year, month, day)
      // insertar remoto
      const cuadranteNoLaboralIns = await Data.insertCuadranteNoLaboral(
        this, idvigilante, fecha, TCUADRANTE_NOLABORAL.tipos.libra, null, null
      )
      // insertar local
      this.cuadranteNoLaboral.push({
        alias: this.turnoKeyCodes,
        estado: cuadranteNoLaboralIns.estado,
        fecha: cuadranteNoLaboralIns.fecha,
        hora_desde: cuadranteNoLaboralIns.hora_desde,
        hora_hasta: cuadranteNoLaboralIns.hora_hasta,
        idcuadrante_nolaboral: cuadranteNoLaboralIns.idcuadrante_nolaboral,
        idtcuadrante_nolaboral: cuadranteNoLaboralIns.idtcuadrante_nolaboral,
        idvigilante: cuadranteNoLaboralIns.idvigilante,
        observaciones: cuadranteNoLaboralIns.observaciones
      })
      this.setCurrentTipoTurno(this.turnoKeyCodes)
      this.turnoKeyCodes = ''
      this.selectNextCell()
    },
    async deleteCuadranteNoLaboral (idcuadranteNoLaboral) {
      // borrar remoto
      await Data.deleteCuadranteNoLaboral(this, idcuadranteNoLaboral)
      // borrar local
      let cuadranteNoLaboralAsignado = _.find(this.cuadranteNoLaboral, (item) => {
        return idcuadranteNoLaboral === item.idcuadrante_nolaboral
      })
      if (cuadranteNoLaboralAsignado) {
        _.remove(this.cuadranteNoLaboral, { idcuadrante_nolaboral: idcuadranteNoLaboral })
      }
      this.setCurrentTipoTurno(null)
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.addVigilante) {
        this.showingDialogs.addVigilante = true
      }
    },
    esFestivo (dia) {
      return _.find(this.festivos, (item) => {
        return (getDay(item.fecha) === dia)
      })
    },
    colorHeader (header) {
      if (header.value !== 'name') {
        const dia = parseInt(header.text)
        if (isWeekend(encodeDate(this.routeParams.year, this.routeParams.month - 1, dia))) {
          return 'fin-semana'
        } else if (this.esFestivo(dia)) {
          return 'festivo'
        } else {
          return 'laboral'
        }
      }
    },
    async submitAddVigilante (formData) {
      const vigilante = await Data.selectVigilantes(this, [formData.idvigilante])
      const existe = _.find(this.vigilantes, (item) => {
        return item.idvigilante === vigilante[0].idvigilante
      })
      if (!existe) {
        this.vigilantes.push(vigilante[0])
        const cuadrante = await Data.selectCuadrante(this, [vigilante[0].idvigilante], this.firstAndLastDay)
        let obj = {}
        obj.value = false
        obj.name = `${vigilante[0].codigo} - ${vigilante[0].nombre_vigilante}`
        for (let day = 1; day < this.daysInMonth + 1; day++) {
          obj['d' + pad(day, 2, '0')] = ''
          for (let item of cuadrante) {
            let dia = getDay(item.fdesde)
            let diaFormatted = 'd' + pad(dia, 2, '0')
            if (item.idvigilante === vigilante[0].idvigilante) {
              obj[diaFormatted] = item.alias
            }
          }
        }
        if (formData.esHabitual) {
          await Data.addVigilantePuestoServicio(this, vigilante[0].idvigilante, this.routeParams.idpuesto_servicio_servicio)
        }
        this.tableItems.push(obj)
      } else {
        this.$alert.showSnackbarError(`El vigilante ${vigilante[0].nombre_vigilante} ya existe en el cuadrante`)
      }
    },
    isOtroTurno (columnName, index) {
      let isFilled = this.tableItems[index][columnName]
      const column = parseInt(columnName.substr(1, 2))
      const idvigilante = this.vigilantes[index].idvigilante
      const cuadrante = _.find(this.cuadrante, (item) => {
        return (
          getDay(item.fdesde) === column &&
          (item.idvigilante === idvigilante)
        )
      })
      if (cuadrante) {
        return (
          (cuadrante.idpuesto_servicio_servicio !== parseInt(this.routeParams.idpuesto_servicio_servicio)) &&
          (isFilled)
        )
      } else {
        return false
      }
    },
    async clickHeader (header) {
      if (header.value !== 'name') {
        const fecha = encodeDate(this.routeParams.year, this.routeParams.month - 1, parseInt(header.text))
        let faltaCompletar = await Data.selectFaltaCompletar(
          this, this.routeParams.idpuesto_servicio_servicio, fecha
        )
        if (faltaCompletar.length > 0) {
          const turnosSinCompletar = _.map(
            faltaCompletar, (turno) => `${turno.cantidad} turno/s "${turno.descripcion}" (${turno.alias})`
          ).join(', ')
          this.$alert.showSnackbarInfo(turnosSinCompletar)
        } else {
          this.$alert.showSnackbarInfo(
            `Todos los turnos del día ${this.$options.filters.shortDate(fecha)} están cubiertos`
          )
        }
      }
    },
    clickSiguienteAnteriorMes (delta) {
      const actual = encodeDate(this.routeParams.year, this.routeParams.month - 1)
      let irA = addMonths(actual, delta)
      this.$appRouter.replace ({
        name: 'cuadrantes__cuadrante-edit',
        params: {
          idpuesto_servicio: this.routeParams.idpuesto_servicio,
          idpuesto_servicio_servicio: this.routeParams.idpuesto_servicio_servicio,
          year: irA.getFullYear(),
          month: irA.getMonth() + 1
        }
      })
    },
  }
}
</script>

<style scoped>
.dia {
  width: 40px;
}
.cell-selected {
  background-color: var(--v-primary-base);
  color: white;
}
.vigilante {
  width: 200px;
}
.festivo {
  color: white;
  background-color: var(--v-red-base);
}
.fin-semana {
  color: white;
  background-color: var(--v-orange-base);
}
.laboral {
  color: black;
}
.otroTurno {
  color: white;
  background-color: var(--v-blueGrey-base);
}
</style>
